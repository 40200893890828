<template>
  <div>
    <!--begin::Dashboard-->
    <!-- <div class="row">
      <div class="col-md-12 mt-6">
        <v-card elevation="0" class="card card-custom  gutter-b" style="background-size: 100%; background-image: url('/media/svg/shapes/abstract-4.svg');">
          <v-card-title class="text-dark">
            Condiciones para la utilización del SIGEACI
            <v-icon>flaticon-file-2</v-icon> 
          </v-card-title>

          

          <v-card-text class="text-h6 text-justify text-dark-50">
            Los usuarios de SIGEACI, aceptan por el sólo hecho de su registro en el sitio, que los datos e 
            información a registrar dentro de los diferentes submódulos SIGEACI es confiable y fehaciente, 
            y que la asignación de usuario, otorga su conformidad para que la información en los diferentes submódulos, 
            formen parte de las estadísticas y reportes que se procesen y publiquen por parte de la Secretaría de Planificación y Programación de la Presidencia, 
            sin que la misma tenga responsabilidad equívoca disponible públicamente para efectos. Los usuarios no podrán realizar actos maliciosos o atentatorios contra el sitio, 
            que de alguna manera pueda dañar, inutilizar, sobrecargar, deteriorar, impedir o limitar la utilización de todas o algunas de las funcionalidades del mismo.
          </v-card-text>

        </v-card>
      </div>
    </div> -->

    

    <div class="row">

        <!-- <div class="col-md-6 col-sm-6 col-xs-12">
            <v-card color="#1c5374" dark style="height: 250px; background-position: calc(100% + 0.5rem) 100%; background-size: 100%; background-image: url('/media/logos/cns.svg');">
                <div class="card-body d-flex align-items-center">
                    <div><h3 class="text-white font-weight-bolder line-height-lg mb-5"> Cooperación <br>Norte-Sur </h3>
                      <p class="text-h6 text-justify text-white">Breve descripción de los proyectos registrados en la modalidad indicada</p>
                      <v-btn text outlined to="/proyectos/cns/administracion">Administrar proyectos</v-btn>
                    </div>
                </div>
            </v-card>
        </div> -->

        
      

      <div class="col-md-6 col-sm-6 col-xs-12 mt-4" v-if="currentRoles.includes('SCO$ADMINISTRADOR') || currentRoles.includes('SCO$SEGUIMIENTOCNS')">

        <template v-if="skeletonLoadingCns">
          <v-sheet
            class="pa-3"
          >
            <v-skeleton-loader
              class="mx-auto"
              max-width="auto"
              height="280"
              type="card"
            ></v-skeleton-loader>
          </v-sheet>
        </template>

        <template v-if="!skeletonLoadingCns">
          <v-card
            class="mx-auto"
            outlined
            height="280"
            dark
            style="background: rgb(20,136,194); background-size: 100%; background-image: url('/media/bg/bg-square.png'), linear-gradient(90deg, rgba(20,136,194,1) 0%, rgba(20,117,166,1) 68%); "
          >
            <v-list-item three-line>
              <!-- <v-list-item-avatar
                tile
                size="150"
                color="grey"
              ></v-list-item-avatar> -->

              <v-list-item-content class="pl-4 mt-4">
              <!--  <div class="text-overline mb-4">
                  OVERLINE
                </div> -->
                <v-list-item-title class="text-h5 mb-1">
                    <h3 class="text-light font-weight-bolder line-height-lg mb-5"> Cooperación <br>Norte-Sur </h3>
                    <v-divider></v-divider>
                    <p class="text-light font-weight-bolder line-height-lg mb-5 text-h3"> {{cantidadCNS }} <small class="text-h5">Proyectos</small> </p>
                    
                    <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                    <v-btn class="mr-2" text outlined to="/proyectos/cns/administracion">Administrar proyectos</v-btn>

                    <v-btn text outlined to="/proyectos/cns/estimacion-presupuesto/admin-dash">Estimación anual del presupuesto</v-btn>
                </v-list-item-title>
                <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
              </v-list-item-content>

            
            </v-list-item>

              <!-- <v-card-actions>
                <v-btn
                  outlined
                  rounded
                  text
                >
                  Button
                </v-btn>
              </v-card-actions> -->
          </v-card>
        </template>
     
      </div>

      <div class="col-md-6 col-sm-6 col-xs-12" v-if="currentRoles.includes('SCO$ADMINISTRADOR') || currentRoles.includes('SCO$SEGUIMIENTOCSS')">
        <div class="row">

          <div class="col-md-12 col-sm-12 col-xs-12 mt-4">

            <template v-if="skeletonLoadingCss">
              <v-sheet
                class="pa-3"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  max-width="auto"
                  height="280"
                  type="card"
                ></v-skeleton-loader>
              </v-sheet>
            </template>
    
            <template v-if="!skeletonLoadingCss">
            <v-card
              class="mx-auto"
              height="280"
              outlined  
              dark
              style="background: rgb(116,215,205); background-size: 100%; background-image: url('/media/bg/bg-square.png'), linear-gradient(90deg, rgba(116,215,205,1) 0%, rgba(40,182,162,1) 68%);"
            >
              <v-list-item three-line>
                <!--  <v-list-item-avatar
                  tile
                  size="150"
                  color="grey"
                ></v-list-item-avatar> -->

                <v-list-item-content class="pl-4 mt-4">
                 <!--  <div class="text-overline mb-4">
                    OVERLINE
                  </div> -->
                  <v-list-item-title class="text-h5 mb-1">
                      <h3 class="text-light font-weight-bolder line-height-lg mb-5"> Cooperación <br>Sur-Sur </h3>
                      <v-divider></v-divider>
                      <p class="text-light font-weight-bolder line-height-lg mb-5 text-h3"> {{cantidadCss}} <small class="text-h5">Proyectos</small> </p>
                      
                      <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                      <v-btn text outlined  to="/proyectos/css/dad/negociacion-directa">Negociación directa</v-btn>
                      <v-btn text outlined  class="ml-2" to="/proyectos/css/dad/negociacion-comixta">Negociación comixta</v-btn>
                  </v-list-item-title>
                  <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
                </v-list-item-content>

               
              </v-list-item>

                <!-- <v-card-actions>
                  <v-btn
                    outlined
                    rounded
                    text
                  >
                    Button
                  </v-btn>
                </v-card-actions> -->
            </v-card>
          </template>


          <!--   <v-card
              color="#44ab96"
              dark
              style="height: 210px;"
            >
              <div class="card-body d-flex align-items-center">
                      <div><h3 class="text-white font-weight-bolder line-height-lg mb-5"> Cooperación <br>Sur-Sur </h3>
                        <p class="text-h6 text-justify text-white">Negociaciones</p>
                        <v-btn text outlined to="/proyectos/css/dad/negociacion-directa">Directa</v-btn>
                        <v-btn text outlined to="/proyectos/css/dad/negociacion-comixta" class="ml-2">Comixtas</v-btn>
                      </div>
                  </div>
            </v-card>
       -->
          </div>


          


        </div>
      </div>

      <div class="col-md-6 col-sm-6 col-xs-12 mt-4" v-if="currentRoles.includes('SCO$ADMINISTRADOR') || currentRoles.includes('SCO$SEGUIMIENTOCOODESC')">
            <div class="card card-custom gutter-b"  style="height: 280px; background-size: 100%; background-image: url('/media/svg/shapes/abstract-4.svg');">
                <div class="card-body d-flex align-items-center">
                    <div>
                        <h3 class="text-dark font-weight-bolder line-height-lg mb-5"> Cooperación <br>Descentralizada </h3>
                        <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                        <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/proyectos/cooperacion-descentralizada/administracion">Administrar proyectos</v-btn>
                    </div>
                </div>
            </div>
        </div>


        <div class="col-md-6 col-sm-6 col-xs-12 mt-4" v-if="currentRoles.includes('SCO$ADMINISTRADOR') || currentRoles.includes('SCO$SEGUIMIENTOACTLOCALES')">
            <div class="card card-custom gutter-b" style="height: 280px;background-size: 100%; background-image: url('/media/svg/shapes/abstract-4.svg');">
                <div class="card-body d-flex align-items-center">
                    <div>
                        <h3 class="text-dark font-weight-bolder line-height-lg mb-5"> Actores locales <br> </h3>
                        <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                        
                        <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/proyectos/actores-locales/interno">Administrar proyectos</v-btn>
                    </div>
                </div>
            </div>
        </div> 


        <div class="col-md-6 col-sm-6 col-xs-12 mt-4" v-if="currentRoles.includes('SCO$ADMINISTRADOR') || currentRoles.includes('SCO$SEGUIMIENTOALIANZAS')">

        <template class="d-none">
          <v-card
            class="mx-auto"
            outlined
            height="280"
            dark
            style="background: rgb(255,182,150); background-size: 100%; background-image: url('/media/bg/bg-square.png'), linear-gradient(90deg, rgba(255, 142, 150,1) 0%, rgba(255, 142, 150,1) 68%); "
          >
            <v-list-item three-line>
              <!-- <v-list-item-avatar
                tile
                size="150"
                color="grey"
              ></v-list-item-avatar> -->

              <v-list-item-content class="pl-4 mt-4">
              <!--  <div class="text-overline mb-4">
                  OVERLINE
                </div> -->
                <v-list-item-title class="text-h5 mb-1">
                    <h3 class="text-light font-weight-bolder line-height-lg mb-5"> Instrumentos de alianzas  </h3>
                    <v-divider></v-divider>
                    <p class="text-light font-weight-bolder line-height-lg mb-5 text-h3"> {{ cantidadAlianzas }} <small class="text-h5">Instrumentos</small> </p>
                    
                    <!-- <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p> -->
                    <v-btn text outlined to="/interno/alianzas/bandeja">Administrar instrumentos</v-btn>
                </v-list-item-title>
                <!-- <v-list-item-subtitle>Greyhound divisely hello coldly fonwderfully</v-list-item-subtitle> -->
              </v-list-item-content>

            
            </v-list-item>

              <!-- <v-card-actions>
                <v-btn
                  outlined
                  rounded
                  text
                >
                  Button
                </v-btn>
              </v-card-actions> -->
          </v-card>
        </template>
     
      </div>


      


      

        <!-- <div class="col-md-6 col-sm-6 col-xs-12">
            <div class="card card-custom gutter-b" style="height: 250px;">
                <div class="card-body d-flex align-items-center">
                    <div><h3 class="text-dark font-weight-bolder line-height-lg mb-5"> Cooperación <br>Sur-Sur </h3>
                    <p class="text-h6 text-justify text-dark-50">Breve descripción de los proyectos registrados en la modalidad indicada</p>
                    
                    <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/proyectos/cns/administracion">Administrar proyectos</v-btn></div>
                </div>
            </div>
        </div> -->


        <div class="col-xxl-12 order-0 order-xxl-2 mt-4 d-none">
            <div class="card card-custom card-stretch gutter-b">
                <div class="card-header border-0 py-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label font-weight-bolder text-dark">Últimos proyectos registrados</span>
                        <span class="text-muted mt-3 font-weight-bold font-size-sm">Últimos proyectos CNS y CSS registrados para de la institución</span>
                    </h3>
                    <div class="card-toolbar">
                        <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3" to="/proyectos/cns/administracion">Ver CNS</v-btn>
                        <v-btn color="light-blue-502" class="white--text btn btn-success font-weight-bold px-6 py-3 ml-2" to="/proyectos/cns/administracion">Ver CSS</v-btn>
                    </div>
                </div>
                <div class="card-body pt-0 pb-3">
                <div class="tab-content">
                    <div class="table-responsive">
                        <table class="table table-head-custom table-vertical-center table-head-bg table-borderless">
                            <thead>
                                <tr class="text-left">
                                    <th class="pl-7" style="min-width: 250px;"><span class="text-dark-75">Proyecto</span></th>
                                    <th style="min-width: 120px;">Tipo de proyecto</th>
                                    <th style="min-width: 120px;">Fecha de registro</th>
                                    <!-- <th style="min-width: 100px;">Acción</th> -->
                                    <!-- <th style="min-width: 100px;"></th> -->
                                    <!-- <th style="min-width: 100px;">rating</th> -->
                                    <!-- <th style="min-width: 101px;"></th> -->
                                </tr>
                            </thead>
                            <tbody>

                                <div v-if="skeletonLoading">
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <v-skeleton-loader
                                            min-height="600px"
                                            type="article, article, article, actions"
                                            >
                                            </v-skeleton-loader>
                                    </v-col>
                                    </v-row>
                                </div>

                                <tr v-for="proyecto in proyectos" :key="proyecto.id">
                                    <td class="pl-0 pt-8">
                                        <div class="d-flex align-items-center">
                                            <div class="symbol symbol-50 symbol-light mr-4"><span class="symbol-label" style="background-color: transparent !important"><img :src="proyecto.tipoProyecto===`CNS` ? `media/logos/logo-cns.png` :  `media/logos/logo-css.png`" alt="" class="h-75 align-self-end"></span></div>
                                        <div>
                                        <a class="text-dark-75 font-weight-bold text-hover-primary mb-1 font-size-lg">{{proyecto.nombreProyecto}}</a><!-- <span class="text-muted font-weight-bold d-block">HTML, JS, ReactJS</span> --></div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{proyecto.tipoProyecto}}</span><span class="text-muted font-weight-bold">{{proyecto.tipoProyecto=="CNS" ? `Cooperación Norte-Sur` : `Cooperación Sur-Sur`}}</span>
                                    </td>
                                    
                                    <td class="text-right"><span class="text-dark-75 font-weight-bolder d-block font-size-lg">{{proyecto.fechaCreacion}}</span><span class="text-muted font-weight-bold">In Proccess</span></td>
                                    <!--<td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">$520</span>
                                        <span class="text-muted font-weight-bold">Paid</span></td>
                                    <td>
                                        <span class="text-dark-75 font-weight-bolder d-block font-size-lg">Intertico</span><span class="text-muted font-weight-bold">Web, UI/UX Design</span></td>
                                    <td> 
                                        <img src="media/logos/stars.png" alt="image" style="width: 5rem;"><span class="text-muted font-weight-bold d-block">Best Rated</span></td>-->
                                    <!-- td class="pr-0 text-right"><a class="btn btn-light-success font-weight-bolder font-size-sm">Ver proyecto</a></td> -->

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

        

         

        


    </div>
    <div class="row d-none">
      <div class="col-xxl-4">
        <MixedWidget1></MixedWidget1>
      </div>
      <div class="col-xxl-4">
        <ListWidget9></ListWidget9>
      </div>
      <div class="col-xxl-4">
        <StatsWidget7></StatsWidget7>
        <StatsWidget12></StatsWidget12>
      </div>

      <div class="col-xxl-4 order-1 order-xxl-1">
        <ListWidget1></ListWidget1>
      </div>
      <div class="col-xxl-8 order-2 order-xxl-1">
        <AdvancedTableWidget2></AdvancedTableWidget2>
      </div>

      <div class="col-xxl-4 order-1 order-xxl-2">
        <ListWidget3></ListWidget3>
      </div>
      <div class="col-xxl-4 order-1 order-xxl-2">
        <ListWidget4></ListWidget4>
      </div>
      <div class="col-lg-12 col-xxl-4 order-1 order-xxl-2">
        <ListWidget8></ListWidget8>
      </div>

      <div class="col-xxl-4 order-1 order-xxl-2">
        <StatsWidget13></StatsWidget13>
      </div>
      <div class="col-xxl-8 order-1 order-xxl-2">
        <AdvancedTableWidget3></AdvancedTableWidget3>
      </div>
    </div>
    <!--end::Dashboard-->
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { OBTENER_PROYECTOS_CNS_EXTERNO, OBTENER_CANTIDAD_TOTAL_PROYECTOS_CNS } from "@/core/services/store/proyectoscns/proyectocns.module";
import {OBTENER_ITEMS_CATALOGO} from "@/core/services/store/catalogos/catalogointerno.module";
import AdvancedTableWidget2 from "@/view/content/widgets/advance-table/Widget2.vue";
import AdvancedTableWidget3 from "@/view/content/widgets/advance-table/Widget3.vue";
import MixedWidget1 from "@/view/content/widgets/mixed/Widget1.vue";
import ListWidget1 from "@/view/content/widgets/list/Widget1.vue";
import ListWidget3 from "@/view/content/widgets/list/Widget3.vue";
import ListWidget4 from "@/view/content/widgets/list/Widget4.vue";
import ListWidget8 from "@/view/content/widgets/list/Widget8.vue";
import ListWidget9 from "@/view/content/widgets/list/Widget9.vue";
import StatsWidget7 from "@/view/content/widgets/stats/Widget7.vue";
import StatsWidget12 from "@/view/content/widgets/stats/Widget12.vue";
import StatsWidget13 from "@/view/content/widgets/stats/Widget13.vue";
import { mapGetters } from "vuex";
export default {
  name: "dashboard",
  components: {
    AdvancedTableWidget2,
    AdvancedTableWidget3,
    MixedWidget1,
    ListWidget1,
    ListWidget3,
    ListWidget4,
    ListWidget8,
    ListWidget9,
    StatsWidget7,
    StatsWidget12,
    StatsWidget13
  },

  data() {
        return { 
        proyectosRegistrados:[],
        proyectos: [],
        skeletonLoading:false,
        tableLoading: false,
        cantidadCNS: 0,
        cantidadCss:0,
        cantidadAlianzas:0,
        skeletonLoadingCns: false,
        skeletonLoadingCss: false,
        skeletonLoadingAli: false,
        skeletonLoadingDesc: false,
        skeletonLoadingALocales: false,
    }
  },

  
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Dashboard" }]);
  },
  methods: {
    setActiveTab1(event) {
      this.tabIndex = this.setActiveTab(event);
    },
    setActiveTab2(event) {
      this.tabIndex2 = this.setActiveTab(event);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },

    //Obtener proyectos
    async obtenerProyectos() {
      this.proyectos = [];
      this.skeletonLoading = true;

      this.$store
        .dispatch(OBTENER_PROYECTOS_CNS_EXTERNO)
        .then(res => {
          if (res.status === 200) {
            this.proyectosRegistrados = this.$store.state.proyectocns.proyectos;

            this.proyectos = this.proyectosRegistrados.slice(0, 5);
          }
            //console.log(this.items)
          this.skeletonLoading = false;
        })
        .catch(() => {
          this.skeletonLoading = false;
        });
    },


    //Obtener cantidad proyectos cns
    async obtenerCantidadCNS() {

      this.cantidadCNS = 0;
      this.skeletonLoadingCns = true;

      this.$store
        .dispatch(OBTENER_CANTIDAD_TOTAL_PROYECTOS_CNS, {estadoId:0})
        .then(res => {
          if (res.status === 200) {
            this.cantidadCNS = this.$store.state.proyectocns.cantidadCns;

            //this.can = this.proyectosRegistrados.slice(0, 5);
          }
            //console.log(this.items)
          this.skeletonLoadingCns = false;
        })
        .catch(() => {
          this.skeletonLoadingCns = false;
          this.cantidadCNS =0;
        });
    },


      //Obtener cantidad proyectos css
      async obtenerCantidadCSS() {

        this.cantidadCss = 0;
        this.skeletonLoadingCss = true;

        this.$store
          .dispatch(OBTENER_ITEMS_CATALOGO, {endpoint: 'Negociacion/Registrados/0'})
          .then(res => {
            if (res.status === 200) {
              this.cantidadCss =res.data;
              //this.can = this.proyectosRegistrados.slice(0, 5);
            }
              //console.log(this.items)
            this.skeletonLoadingCss = false;
          })
          .catch(() => {
            this.skeletonLoadingCss = false;
            this.cantidadCss =0;
          });
        },

  },

  created(){
    this.obtenerCantidadCNS();
    this.obtenerCantidadCSS();
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo", "currentUser"]),

    getFullName() {
      return (
        this.$store.state.auth.user.nombres +
        " " +
        this.$store.state.auth.user.apellidos
      );
    },

    firstName:{
      get(){
         return this.$store.state.auth.user.nombres.split(' ')[0];
      }
    },

    lastName:{
      get(){
         return this.$store.state.auth.user.apellidos.split(' ')[0];
      }
    },

     email:{
      get(){
         return this.$store.state.auth.user.correo;
      }
    },

    usuario:{
      get(){
         return this.$store.state.auth.user.usuario;
      }
    },

    currentRoles:{
      get(){
         return this.$store.state.auth.roles;
      }
    },
  }
};
</script>
